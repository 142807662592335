//@ts-nocheck
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import baseInstance from "../api";
import ProgressBar from "../components/ProgressBar";
import GaugePdf from "../components/GaugePdf";
import { useLoadScript } from "@react-google-maps/api";
import generatePDF from "react-to-pdf";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import useTranslateNested from "../hooks/index";
import LanguageSwitcher from "../components/LanguageSwitcher";
import { MapForReport } from "../components/PlacesAutocomplete";
import TopGaugeCattle from "../components/TopGaugeCattle";


interface RiskDataProps {
  profitData: RiskDataById | undefined;
}


const LivestockPDF = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [language, setLanguage] = useState("en");
  const [profitData, setProfitData] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("about");
  const risk_score_id = searchParams.get("risk_score_id");

  // const sessionId = searchParams.get("session_id");
  const targetRef = useRef();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCEVC1A3YRL-S4zGuuLgHnlYjlQr90P5iA",
    libraries: ["places"],
  });
  useEffect(() => {
    if (isLoaded) {
      console.log("Google Maps script loaded successfully!");
    }
  }, [isLoaded]);

  const climate_indices = ["Drought", "Temperature humidity"];
  const water_indices = ["Water stress", "Ground Water Availability"];
  const profitability_indices = ["Profitability"];

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const translatedData = useTranslateNested(data, localStorage.getItem("lang"));

  async function getGenReport() {
    setLoading(true);
    try {
      const res = await baseInstance.get(
        `/projects/report-data/livestocks/by-id/${risk_score_id}`
      );
      setProfitData(res.data);
      // console.log(res.data, " full");
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handlePrint = () => {
    const printContents = document.getElementById("printablediv")?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  };
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    i18n?.changeLanguage(lang);
  };
  const soil_indices = [
    t("Top Soil Fertility"),
    t("Soil pH"),
    t("Nutrient capacity"),
  ];
  useEffect(() => {
    let temp1 = profitData?.adaptations ?? {};
    let newData = {}; // Temporary object to hold data

    Object.keys(temp1).forEach((key) => {
      if (Object.keys(newData).includes(temp1[key]?.Pillar)) {
        newData = {
          ...newData,
          [temp1[key]?.Pillar]: [...newData?.[temp1[key]?.Pillar], temp1[key]],
        };
      } else {
        newData[temp1[key]?.Pillar] = [temp1[key]];
      }
    });

    // Update the state with the new data
    setData(newData);
  }, [profitData]);

  useEffect(() => {
    getGenReport();
  }, []);

  const calculateDateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Difference in milliseconds
    const differenceInTime = end - start;

    // Convert time difference to days, months, and years
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const differenceInMonths = differenceInDays / 30.44; // Approximate months
    const differenceInYears = differenceInMonths / 12;

    return {
      days: Math.floor(differenceInDays),
      months: Math.floor(differenceInMonths),
      years: differenceInYears,
    };
  };

  const { months } = calculateDateDifference(
    profitData?.startDate,
    profitData?.endDate
  );
  return (
    <>
      {/* Invoice */}
      {/* <div id="printablediv" className="max-w-[85rem]  mx-auto my-4 sm:my-10"> */}
      <div
        id=""
        className="max-w-[70rem]  mx-auto my-4 sm:my-10 bg-grey-100 shadow-2xl pb-4"
      >
        <div className="w-100 gap-3 flex justify-start p-5 items-center">
          <button
            onClick={() =>
              generatePDF(targetRef, {
                filename: `report-${risk_score_id}.pdf`,
              })
            }
            class="bg-[#002952] hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <svg
              class="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
            </svg>
            <span>PDF</span>
          </button>
          <button
            onClick={() =>
              handlePrint(targetRef, {
                filename: `report-${risk_score_id}.pdf`,
              })
            }
            class="bg-[#318CE7] hover:bg-gray-400 text-white font-bold py-2 px-4 rounded inline-flex items-center"
            id=""
          >
            <i className="bi bi-printer-fill mr-3"></i>
            <span>Print</span>
          </button>
          {/* <LanguageSwitcher onLanguageChange={handleLanguageChange} /> */}
        </div>

        {/* Invoice */}
        <div
          id="printablediv"
          className="max-w-[85rem]  mx-auto my-4 sm:my-10"
          ref={targetRef}
        >
          <div className="flex  justify-center items-center  border-gray-200 dark:border-gray-700  sm:my-4">
            <div className="mb-8">
              <img alt="Logo" className="w-52 mx-auto" src="/img/logo.png" />
            </div>
          </div>
          {/* Grid */}
          <div className="grid md:grid-cols-2 gap-3 pb-5 px-4 sm:px-6 lg:px-8  border-b border-gray-200 dark:border-gray-700">
            <div>
              <div className="grid space-y-3">
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[108px] max-w-[200px] text-gray-500">
                    {t("Full Name")}:
                  </dt>
                  <dd className="text-gray-800 dark:text-gray-200">
                    <input
                      type="text"
                      defaultValue={profitData?.debtor_name}
                      id="input-label"
                      className="min-w-[100%] rounded-sm border-gray-200   dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                      placeholder="Full Name"
                    />
                  </dd>
                </dl>
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[100px] max-w-[200px] text-gray-500">
                    {t("Phone Number")}:
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    <input
                      type="tel"
                      id="phone_number"
                      defaultValue={profitData?.debtor_contacts}
                      className="min-w-[100%] rounded-sm border-gray-200   dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                      placeholder="+254"
                    />
                  </dd>
                </dl>
              </div>
            </div>
            {/* Col */}
            <div>
              <div className="grid space-y-3">
                <dl className="grid sm:flex gap-x-3 text-sm">
                  <dt className="min-w-[150px] max-w-[200px] text-gray-500">
                    Location(Town):
                  </dt>
                  <dd className="font-medium text-gray-800 dark:text-gray-200">
                    <input
                      type="text"
                      id="input-label"
                      defaultValue={profitData?.location_name}
                      className="min-w-[100%] rounded-sm border-gray-200   dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                      placeholder="Location"
                    />
                    <address className="not-italic font-normal">
                      {`LatLng(${profitData?.latitude || "Latitude"}, ${
                        profitData?.longitude || "Longitude"
                      })`}
                    </address>
                  </dd>
                </dl>
              </div>
            </div>
            {/* Col */}
          </div>
          {/* End Grid */}
          <div className="pt-8 px-4 sm:px-6 lg:px-8">
            {/* Grid */}

            {/* End Grid */}

            <div className="mt-5 mb-9">
              <h4 className="text-lg  font-semibold text-gray-800 dark:text-gray-200">
                Detailed Risk Report Analysis
              </h4>
            </div>

            {/* Table */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 border border-gray-200 p-4 rounded-lg  dark:border-gray-700">
              <div className="flex flex-col items-center ">
                <h3 className="mb-2 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                  Map
                </h3>

                <div className="h-80 md:h-full w-full">
                  {isLoaded ? ( <MapForReport lat={profitData?.latitude} lng={profitData?.longitude} /> ) : (
                  <p>Loading map...</p>
                )}
                </div>
              </div>
              <div className="flex flex-col  items-center ">
                <h3 className="  font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">
                  Composite Risk Score
                </h3>

                <ProgressBar
                  //@ts-ignore
                  isBig={true}
                  progress={profitData?.composite_total_risk || 0}
                  width={300}
                />
                <div className="w-full md:pl-10">
                  <div className="mt-2 sm:mt-2">
                    <h4 className="text-xs font-semibold uppercase text-gray-800 dark:text-gray-200">
                      Summary
                    </h4>
                    <ul className="mt-3 flex flex-col bg-[#FAFAFA]">
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                        <div className="flex items-center justify-between w-full">
                          <span>Loan Period</span>
                          <span>{months} Months</span>
                        </div>
                      </li>
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg ">
                        <div className="flex items-center justify-between w-full">
                          <span>Livestock</span>
                          <span>{profitData?.livestock_type}</span>
                        </div>
                      </li>
                      <li className="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-semibold bg-gray-50  text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:bg-slate-800 ">
                        <div className="flex items-center justify-between w-full">
                          <span>Biodiversity</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="green"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-badge-check"
                          >
                            <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" />
                            <path d="m9 12 2 2 4-4" />
                          </svg>{" "}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* End Table */}
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-5 mb-10 ">
              <div className="group flex flex-col  p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                <p className="text-center font-semibold pt-3">
                  {" "}
                  {t("CLIMATE RISK")}
                </p>
                <div className="p-4 py-6 items-center bg-[#F9F9F9]">
                  <div className="w-100 md:w-[100%] flex justify-center">
                    <GaugePdf
                      level={profitData?.climate_scores?.composite_climate_risk}
                    />
                  </div>
                  <ProgressBar
                    piller={climate_indices[0]}
                    width={"100%"}
                    progress={profitData?.climate_scores.drought_risk}
                  />
                  <ProgressBar
                    width={"100%"}
                    piller={climate_indices[1]}
                    progress={
                      profitData?.climate_scores
                        ?.temperature_humidity_index_risk
                    }
                  />
                </div>
              </div>
              <div className="group flex flex-col  p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                <p className="text-center font-semibold pt-3">
                  {t("WATER RISK")}
                </p>
                <div className="p-4 py-6 items-center bg-[#F9F9F9]">
                  <div className="w-100 md:w-[100%] flex justify-center">
                    <GaugePdf
                      level={profitData?.water_scores?.composite_water_risk}
                    />
                  </div>
                  <ProgressBar
                    piller={water_indices[0]}
                    width={"100%"}
                    progress={profitData?.water_scores.water_stress_risk}
                  />
                  <ProgressBar
                    piller={water_indices[1]}
                    width={"100%"}
                    progress={profitData?.water_scores?.ground_water_risk}
                  />
                </div>
              </div>
              <div className="group flex flex-col  p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                <p className="text-center font-semibold pt-3">
                  {t("PROFITABILITY RISK")}
                </p>
                <div className="p-4 py-6 items-center bg-[#F9F9F9]">
                  <div className="w-100 md:w-[100%] flex justify-center">
                    <GaugePdf
                      level={
                        profitData?.profitability_scores?.profitability_risk
                      }
                    />
                  </div>
                  <ProgressBar
                    piller={profitability_indices[0]}
                    width={"100%"}
                    progress={
                      profitData?.profitability_scores?.profitability_risk
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              <h4 className="text-2xl mb-2 font-semibold text-gray-800 dark:text-gray-200">
                Climate Adaptation Suggestions To Improve Your Score
              </h4>

              {Object.keys(data).map((key) => {
                return (
                  <>
                    <h4 className="text-lg mb-2 font-semibold text-gray-800 dark:text-gray-200">
                      {key}
                    </h4>
                    {data[key].map(({ Suggestion }) => {
                      return (
                        <>
                          <p className="text-gray-500 mt-2">
                            {profitData?.adaptations[1] && (
                              <li>{Suggestion}</li>
                            )}
                          </p>
                        </>
                      );
                    })}
                  </>
                );
              })}
            </div>
            <div className="mt-8">
              <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                Thank you!
              </h4>
              <p className="text-gray-500">
                If you have any questions concerning this report, use the
                following contact information:
              </p>
              <div className="mt-2">
                <p className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                  info@adapta.earth
                </p>
              </div>
            </div>
            <p className="mt-5 text-sm text-gray-500">© 2023 ADAPTA.</p>

            <div className="mt-5 px-4 sm:px-6 lg:px-8">
              <p>Add a comment(Optional)</p>
              <textarea
                className=" mt-2 py-3 px-4 block w-full border-gray-200 rounded-md border text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                rows={3}
                placeholder="Enter a comment ..."
                defaultValue={profitData?.reviewer_comments}
              />
            </div>
          </div>

          {/* <button
            type="button"
            onClick={toggleModal}
            className=" mt-3 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
          >
            Close
          </button> */}
        </div>
        {/* End Invoice */}
      </div>
    </>
  );
};

export default LivestockPDF;
