import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import { AboutUs } from "./pages/AboutUs";
import { ContactUs } from "./pages/ContactUs";
import Help from "./pages/Help";
import { Banner } from "./components/CookieBanner";
import Checkout from "./pages/Checkout";
import Success from "./pages/Success";
import CountryPrices from "./pages/CountryPrices";
import CheckoutLivestock from "./pages/CheckoutLivestock";
import SuccessLivestock from "./pages/SuccessLivestock";
import LivestockPDF from "./pages/LivestockPdf"
import { Toaster } from "react-hot-toast";
import VerifyHuman from "./pages/VerifyHuman";
import VerifyHumanLivestock from "./pages/VerifyHumanLivestock";
import Survey from "./pages/Survey";
import { LanguageProvider } from "./context/Language";
import i18n from "./i18n";
import Register from "./pages/Register";
import Plans from "./pages/Plans";
import Congrats from "./pages/Congrats";
import { PriceProvider } from "./context/Price";
import CheckoutPlans from "./pages/CheckoutPlans";
import Support from "./pages/Support";
import CropPDF from "./pages/CropPdf";
// import Reports from "./pages/Reports";

if (!localStorage.getItem("lang")) {
  // If it doesn't exist, set the key with the specified value
  localStorage.setItem("lang", "en");
} else {
  //@ts-ignore
  i18n?.changeLanguage(localStorage.getItem("lang"));
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },

  {
    path: "/help",
    element: <Help />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/checkout-livestock",
    element: <CheckoutLivestock />,
  },
  {
    path: "success",
    element: <Success />,
  },
  {
    path: "success-livestock",
    element: <SuccessLivestock />,
  },
  {
    path: "subscription/livestock",
    element: <LivestockPDF />,
  },
  {
    path: "subscription/crop",
    element: <CropPDF />,
  },
  // {
  //   path: "subscription/allreports",
  //   element: <Reports />,
  // },
  {
    path: "/prices-breakdown",
    element: <CountryPrices />,
  },
  {
    path: "/verify",
    element: <VerifyHuman />,
  },
  {
    path: "/verify-livestock",
    element: <VerifyHumanLivestock />,
  },
  {
    path: "/survey",
    element: <Survey />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/plans",
    element: <Plans />,
  },
  {
    path: "/success-subscription",
    element: <Congrats />,
  },
  {
    path: "/plancheckout",
    element: <CheckoutPlans />,
  },
  {
    path: "/support",
    element: <Support />,
  },
]);
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Toaster />
    <LanguageProvider>
      <PriceProvider>
        <RouterProvider router={router} />
      </PriceProvider>
    </LanguageProvider>
    <Banner />
  </React.StrictMode>
);
